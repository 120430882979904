import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { OpeningSoonContext } from "../../context/openingSoonContext";

const Footer = () => {
  const { setWarning } = useContext(OpeningSoonContext);

  const year = new Date().getFullYear();

  return (
    <div
      className="bg-[#131719] py-14 max-sm:pb-10"
      style={{
        background: "rgba(0, 0, 0, 0.24)",
        backdropFilter: "blur(22px)",
      }}
    >
      {/* max-lg:flex-col max-lg:items-center max-lg:px-[20px] max-lg:py-[32px] */}
      <div className="container m-auto flex max-xl:flex-col-reverse gap-8 justify-between max-lg:rounded-[32px] max-lg:gap-[30px] max-sm:gap-4">
        {/* Left Side */}
        <div className="xl:w-[60%] flex max-xl:justify-between max-md:flex-col 2xl:gap-[84px] md:gap-4 gap-10 max-sm:gap-5">
          <div className="flex flex-col gap-5 pl-4 max-sm:gap-3">
            <p className="text-lg font-[500] text-white">Contact Us</p>
            <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">Help</p>
            </Link>
            <Link to="https://help.niza.io/hc/en-us/categories/22116589504657-FAQ">
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">FAQ</p>
            </Link>
            <a href="mailto:support@niza.io">
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">support@niza.io</p>
            </a>
            {/* <a href="mailto:press@public.com">
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">press@public.com</p>
            </a> */}
          </div>
          <div className="flex flex-col gap-5 pl-4 max-sm:gap-3">
            <p className="text-lg font-[500] text-white">Resources</p>
            <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">Learn</p>
            </Link>
            <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">Support</p>
            </Link>
          </div>
          <div className="flex flex-col gap-5 pl-4 max-sm:gap-3">
            <p className="text-lg font-[500] text-white">Products</p>
            <Link to={process.env.REACT_APP_SIGN_IN}>
              <p className="text-dark-text text-sm font-[500]">
                <span className="text-custom-primary">NIZA</span> CEX{" "}
                <span className="border-green-500 border-[1px] rounded-[4px] bg-green-500/10 text-green-500 text-[10px] px-1.5 py-1.5 ml-2">
                  1 JAN. 2024
                </span>
              </p>
            </Link>
            <Link to={process.env.REACT_APP_TRADE}>
              <p className="text-dark-text text-sm font-[500]">
                <span className="text-custom-primary">NIZA</span> Trade{" "}
                <span className="border-custom-primary border-[1px] rounded-[4px] bg-custom-primary/10 text-custom-primary text-[10px] px-1.5 py-1.5 ml-2">
                  NEW
                </span>
              </p>
            </Link>
            <div
              className="text-dark-text text-sm font-medium cursor-pointer"
              onClick={() => setWarning(true)}
            >
              <span className="text-custom-primary">NIZA</span> Wallet{" "}
              <span className="border-custom-primary border rounded-[4px] bg-custom-primary/10 text-custom-primary text-[10px] px-1.5 py-1.5 ml-2">
                NEW
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-5 pl-4 max-sm:gap-3">
            <p className="text-lg font-medium text-white">Company</p>
            <Link to="/about">
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">About Us</p>
            </Link>
            <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">Newsroom</p>
            </Link>
            <Link to="/contact">
              <p className="text-dark-text text-sm hover:text-white hover:underline hover:decoration-white">Have questions?</p>
            </Link>
          </div>
        </div>
        <div className="md:w-[614px] flex xl:justify-end max-xl:ml-4">
          <div className="flex flex-col gap-6 items-start max-sm:gap-3">
            <div>
              <img src="/logo.png" alt="" className="h-10 w-fit" />
            </div>
            <p className="text-xl font-[700] text-dark-text max-sm:text-lg">Empower your Digital Wealth</p>
            <p className="text-sm text-dark-text max-sm:text-[13px]">
              Join our community. Stay updated on the latest news<br/> and
              announcements in the crypto world.
            </p>
            <div>
              <p className="text-sm text-dark-text mb-[11px] max-sm:text-xs">Community</p>
              <div className="flex gap-3">
                <Link to="https://www.instagram.com/nizaglobal" target="_blank">
                  <div className="p-3.5 bg-[#2C3447] rounded-md cursor-pointer hover:bg-[#434f6b] max-sm:p-2.5">
                    <img src="/icons/instagram.svg" alt="" width={20} />
                  </div>
                </Link>
                <Link to={process.env.REACT_APP_DISCORD} target="_blank">
                  <div className="p-3.5 bg-[#2C3447] rounded-md cursor-pointer hover:bg-[#434f6b] max-sm:p-2.5">
                    <img src="/icons/discord.svg" alt="" width={20} />
                  </div>
                </Link>
                <Link to={process.env.REACT_APP_TELEGRAM} target="_blank">
                  <div className="p-3.5 bg-[#2C3447] rounded-md cursor-pointer hover:bg-[#434f6b] max-sm:p-2.5">
                    <img src="/icons/telegram.svg" alt="" width={20} />
                  </div>
                </Link>
                <Link to={process.env.REACT_APP_TWITTER} target="_blank">
                  <div className="p-3.5 bg-[#2C3447] rounded-md cursor-pointer hover:bg-[#434f6b] max-sm:p-2.5">
                    <img src="/icons/xTwitter.svg" alt="" width={20} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center text-dark-text text-base mt-[38px] max-sm:text-[13px] max-sm:mt-9">
        Copyright Niza Global © {year}
      </div>
      <iframe src="https://trade.niza.io?iframe=true" width="0" height="0" style={{display:"none"}}></iframe>
    </div>
  );
};

export default Footer;
