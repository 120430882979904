import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
// import { faClock } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Announcement = () => {

  const steps = [
    { title: "Create Your Account", desc: "Create your account with two easy steps.<br/><br/> Your account and personal identity are guaranteed safe.", svg: <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 5.67041C4 2.63284 6.46243 0.17041 9.5 0.17041C12.5376 0.17041 15 2.63284 15 5.67041C15 8.70798 12.5376 11.1704 9.5 11.1704C6.46243 11.1704 4 8.70798 4 5.67041Z" fill="white"/><path d="M0 22.6704C0 17.4237 4.2533 13.1704 9.5 13.1704C14.7467 13.1704 19 17.4237 19 22.6704V22.6738C19 22.7269 18.9995 22.7802 18.9986 22.833C18.9928 23.1777 18.8099 23.4951 18.5146 23.6729C15.8809 25.2586 12.7954 26.1704 9.5 26.1704C6.20457 26.1704 3.11906 25.2586 0.485414 23.6729C0.190084 23.4951 0.00716388 23.1777 0.00136852 22.833C0.000459671 22.7789 0 22.7247 0 22.6704Z" fill="white"/><path d="M22 7.17041C22 6.61813 21.5523 6.17041 21 6.17041C20.4477 6.17041 20 6.61813 20 7.17041V10.1704H17C16.4477 10.1704 16 10.6181 16 11.1704C16 11.7227 16.4477 12.1704 17 12.1704H20V15.1704C20 15.7227 20.4477 16.1704 21 16.1704C21.5523 16.1704 22 15.7227 22 15.1704V12.1704H25C25.5523 12.1704 26 11.7227 26 11.1704C26 10.6181 25.5523 10.1704 25 10.1704H22V7.17041Z" fill="white"/></svg> },
    { title: "Stake your $NIZA tokens.", desc: "Don't let your $NIZA tokens sit idle without generating passive income. Invest your tokens and earn up to 35% annually.", header: "High earnings 30% - 35% APY", svg: <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6665 3.17041C9.4868 3.17041 3.6665 8.99071 3.6665 16.1704C3.6665 23.3501 9.4868 29.1704 16.6665 29.1704C23.8462 29.1704 29.6665 23.3501 29.6665 16.1704C29.6665 8.99071 23.8462 3.17041 16.6665 3.17041ZM17.6665 8.17041C17.6665 7.61813 17.2188 7.17041 16.6665 7.17041C16.1142 7.17041 15.6665 7.61813 15.6665 8.17041V16.1704C15.6665 16.7227 16.1142 17.1704 16.6665 17.1704H22.6665C23.2188 17.1704 23.6665 16.7227 23.6665 16.1704C23.6665 15.6181 23.2188 15.1704 22.6665 15.1704H17.6665V8.17041Z" fill="white"/></svg> },
    { title: "Get daily rewards", desc: "Easy rewards!<br/><br/> Watch your balance grow daily.", header: "~48 NIZA/Day with 50K share", svg: <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8335 4.17041C11.4528 4.17041 10.3335 5.2897 10.3335 6.67041C10.3335 8.05112 11.4528 9.17041 12.8335 9.17041H15.3335V15.1704H4.8335C3.45279 15.1704 2.3335 14.0511 2.3335 12.6704V11.6704C2.3335 10.2897 3.45278 9.17041 4.8335 9.17041H9.09127C8.61263 8.45536 8.3335 7.59548 8.3335 6.67041C8.3335 4.18513 10.3482 2.17041 12.8335 2.17041C14.2471 2.17041 15.5085 2.82224 16.3335 3.84175C17.1585 2.82224 18.4199 2.17041 19.8335 2.17041C22.3188 2.17041 24.3335 4.18513 24.3335 6.67041C24.3335 7.59548 24.0544 8.45536 23.5757 9.17041H28.8335C30.2142 9.17041 31.3335 10.2897 31.3335 11.6704V12.6704C31.3335 14.0511 30.2142 15.1704 28.8335 15.1704H17.3335V9.17041H19.8335C21.2142 9.17041 22.3335 8.05112 22.3335 6.67041C22.3335 5.2897 21.2142 4.17041 19.8335 4.17041C18.4528 4.17041 17.3335 5.2897 17.3335 6.67041V9.17041H15.3335V6.67041C15.3335 5.2897 14.2142 4.17041 12.8335 4.17041Z" fill="white"/><path d="M15.3335 17.1704H4.3335V26.1704C4.3335 27.8273 5.67664 29.1704 7.3335 29.1704H15.3335V17.1704Z" fill="white"/><path d="M17.3335 17.1704V29.1704H26.3335C27.9904 29.1704 29.3335 27.8273 29.3335 26.1704V17.1704H17.3335Z" fill="white"/></svg> },
  ]

  return (
    <div className="relative w-full h-[250px] ">
      <div className="absolute w-full 2xl:bottom-[-20%] xl:bottom-[-50%] max-lg:top-[-5%] max-md:top-[-20%] max-lg:px-0 z-[1]">
        <div className="w-11/12 2xl:w-9/12 m-auto relative xl:mt-[180px]">
          <div className="w-[100%] relative m-auto">
            <div className="rounded-[20px] border-2 border-[#FFFFFF1A] p-8 backdrop-blur-md flex flex-col gap-12 max-md:gap-8 max-sm:gap-6" style={{fontFamily: 'Geist Variable'}}>
              <div className="flex justify-between items-center gap-4 max-md:flex-col max-md:items-start">
                <div className="flex flex-col gap-2">
                  <p className="text-white text-4xl max-sm:mb-2 font-semibold">
                    Stake with <span className="text-custom-primary">Niza Global</span>
                  </p>
                  <p className="text-gray-300 text-lg max-sm:text-base">Effortlessly increase your crypto holdings by staking your coins with us</p>
                </div>
                <Link 
                  to={process.env.REACT_APP_SIGN_UP} 
                  className="text-black text-lg bg-custom-primary hover:bg-custom-primary-dark transition-colors cursor-pointer rounded-lg font-semibold py-3 px-4 w-44 
                    flex items-center justify-center max-md:py-2 max-md:px-2 max-md:w-32 max-md:text-base"
                >
                  Get Started
                </Link>
              </div>
              <div className="flex gap-4 max-lg:flex-col">
                {steps.map((item, indx) => (
                  <div 
                    className={`${indx === 0 ? "border border-custom-primary " : ' '} p-5 rounded-[20px] w-full flex gap-4 
                      items-start bg-[#FFFFFF1A] sm:min-h-[200px] max-sm:flex-col
                    `}
                  >
                    <div className="rounded-full p-3 bg-[#aaaa22]">
                      {item.svg}
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-1">
                        {indx !== 0 && 
                          <div className="border border-custom-primary rounded-md flex gap-2 items-center justify-center max-w-[208px] py-0.5 px-0.5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.52683 2.47656C5.65869 2.47656 2.52295 5.6123 2.52295 9.48044C2.52295 13.3486 5.65869 16.4843 9.52683 16.4843C13.395 16.4843 16.5307 13.3486 16.5307 9.48044C16.5307 5.6123 13.395 2.47656 9.52683 2.47656ZM10.0656 5.17036C10.0656 4.87281 9.82437 4.6316 9.52683 4.6316C9.22928 4.6316 8.98807 4.87281 8.98807 5.17036V9.48044C8.98807 9.77799 9.22928 10.0192 9.52683 10.0192H12.7594C13.0569 10.0192 13.2981 9.77799 13.2981 9.48044C13.2981 9.18289 13.0569 8.94168 12.7594 8.94168H10.0656V5.17036Z" fill="#DCDC2C"/></svg>
                            <p className="text-xs text-custom-primary">{item.header}</p>
                          </div>
                        }
                        <p className="font-semibold text-gray-50 text-xl max-sm:text-lg">{item.title}</p>
                      </div>
                      <p className="text-gray-300 max-sm:text-sm">{parse(item.desc)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
