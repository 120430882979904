import useSWR from 'swr';
import { fetcherGET } from '../swr/fetcher';
import { useState } from 'react';

export const useGetCoinData = () => {
  const { data, isLoading, error } = useSWR(
    `${process.env.REACT_APP_COIN_DATA}`,
    fetcherGET
  )
  return { coinData: isLoading || error ? [] : data.data }
}