import React from "react";
import Hero from "../components/about/Hero";
import AboutText from "../components/about/AboutText";
import WhyNiza from "../components/about/WhyNiza";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Niza - About</title>
      </Helmet>
      <div>
        <Hero />
        <AboutText />
        <WhyNiza />
      </div>
    </>
  );
};

export default About;
